.App {
    text-align: left;
  }
  .customContainer {
    padding: 2rem;
  }
  .tableContainer {
    padding: 1rem 0;
  }
  .dataTable {
    background-color: #cecece10;
    width: 100%;
    box-shadow: inset 0 0 3px 1px #cecece;
    border-radius: 0px;
  }
  .tableContainer p {
    font-weight: 600;
  }
  .dataTable a {
    text-decoration: none;
    border-bottom: 1px solid blue;
  }
  .dataTable td {
    height: 30px;
    border-right: 1px solid #cecece50;
  }
  .dataTable tbody tr:nth-child(even) td {
    background-color: #cecece50;
    border-right: 1px solid #cecece50;
  }
  .dataTable thead tr:first-child th {
    width: 10%;
  }
  .topHeading {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .generatBtn {
    width: 110px;
    height: 48px;
    border-radius: 8px;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid blue;
    font-weight: 600;
  }
